'use client'

import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  className?: string
  scrollToEnd?: boolean
}

export default function ChatContainer({
  children,
  scrollToEnd,
  className,
}: PropsWithChildren<Props>) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToEnd && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [scrollToEnd])

  return (
    <div
      ref={ref}
      className={twMerge(
        'flex flex-col items-start justify-start gap-4 overflow-y-auto rounded-xl bg-sq-gray-200 px-4 pb-8 pt-4 *:max-w-[90%] md:*:max-w-[80%] lg:*:max-w-[70%] xl:*:max-w-[60%]',
        className
      )}
    >
      {children}
    </div>
  )
}
