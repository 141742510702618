import React, { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

export type MessagePosition = 'left' | 'right'

type Props = {
  position: MessagePosition
  className?: string
}

const positionToStyle = {
  left: 'bg-sq-gray-100 rounded-t-xl rounded-bl-sm rounded-br-xl mr-auto items-start',
  right: 'bg-white rounded-bl-xl rounded-br-sm rounded-t-xl ml-auto w-auto items-end',
} satisfies Record<MessagePosition, string>

export default function ChatMessage({ position, className, children }: PropsWithChildren<Props>) {
  return (
    <div
      className={twMerge(
        'flex w-full flex-shrink flex-col gap-2.5 p-4',
        positionToStyle[position],
        className
      )}
    >
      {children}
    </div>
  )
}
