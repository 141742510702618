import Badge, { BadgeVariant } from '../Badge/Badge'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = Omit<ComponentPropsWithoutRef<'button'>, 'title' | 'children'> & {
  count: number
  isActive: boolean
  title: string
  badgeVariant: BadgeVariant
}

export default function MenuItem({
  count,
  isActive,
  badgeVariant,
  className,
  title,
  ...props
}: Props) {
  return (
    <button
      {...props}
      className={twMerge(
        'flex flex-row items-center py-5 text-left text-xl underline-offset-4',
        className
      )}
    >
      <span className={`${isActive ? 'underline' : ''} mb-2`}>{title}</span>
      <Badge variant={badgeVariant} className="ml-2 px-2 py-0 text-base">
        {count > 0 ? String(count).padStart(2, '0') : '0'}
      </Badge>
      <ChevronRightIcon height={20} width={20} strokeWidth={2} className="ml-auto mr-4" />
    </button>
  )
}
