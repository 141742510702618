import { Slot } from '@radix-ui/react-slot'
import { ComponentProps, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export const buttonVariants = ['primary', 'secondary', 'gray', 'link', 'outline'] as const

export type ButtonVariants = (typeof buttonVariants)[number]

type Props = Omit<ComponentProps<'button'>, 'disabled'> & {
  variant: ButtonVariants
  isDisabled?: boolean
  asChild?: boolean
}

const variantToStyle = {
  primary: 'bg-primary text-white',
  secondary: 'bg-secondary text-primary',
  gray: 'bg-sq-gray-200 text-primary hover:bg-sq-gray-300',
  link: 'text-primary bg-transparent underline underline-offset-4',
  outline: 'border border-sq-gray-200 text-primary bg-transparent hover:bg-sq-gray-50',
} satisfies Record<ButtonVariants, string>

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ children, isDisabled, variant, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        {...props}
        ref={ref}
        disabled={isDisabled}
        className={twMerge(
          'flex w-full flex-row items-center justify-center gap-2 rounded-full bg-primary px-4 py-3 text-center font-medium text-white transition-all duration-300',
          isDisabled && 'opacity-50 hover:cursor-not-allowed',
          variantToStyle[variant],
          props.className
        )}
      >
        {children}
      </Comp>
    )
  }
)

Button.displayName = 'Button'

export default Button
