import React from 'react'

export type BadgeVariants = 'default' | 'inline' | 'only-code'

type BadgeProps = {
  discount?: number
  code?: string
  link: string
  variant?: BadgeVariants
}

export default function Badge({ discount, code, link, variant }: BadgeProps) {
  if (variant === 'only-code') {
    return (
      <div>
        <div className="flex items-center justify-center rounded-md bg-sq-green-100 px-4 py-2">
          <span className="text-sm font-normal text-primary">
            Código aplicado:
            <strong className="text-sm font-bold text-primary"> {code} </strong>
          </span>
          <a
            href={link}
            target="_blank"
            className="ml-2 text-xs font-bold text-primary underline"
            rel="noreferrer"
          >
            Saiba mais
          </a>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="flex items-center justify-center rounded-md bg-sq-green-100 px-4 py-2">
        {discount && (
          <small className="text-center text-sm text-primary">
            Você ganhou <strong>{discount}% OFF</strong> no primeiro aluguel!
            <a
              href={link}
              target="_blank"
              className="ml-2 text-xs font-bold text-primary underline"
              rel="noreferrer"
            >
              Saiba mais
            </a>
          </small>
        )}
      </div>

      {variant !== 'inline' && (
        <div>
          <span className="text-xs font-normal text-primary">*Código utilizado:</span>
          <span className="text-xs font-medium text-primary"> {code} </span>
        </div>
      )}
    </div>
  )
}
