'use client'

import React from 'react'
import Image from 'next/image'
import logoDark from 'public/logo-dark.svg'
import logoColor from 'public/logo-color.svg'
import { EventType, GetButtonEventProperties, ButtonTrackClickType, track } from '@/tracks'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

type Props = {
    variant?: 'dark' | 'color'
    className?: string
}

const trackClickLogo = () =>
    track(EventType.ButtonClick, GetButtonEventProperties('Logo', ButtonTrackClickType.Logo))

const Logo = React.memo(({ variant = 'color', className }: Props = {}) => {
    return (
        <Link href="/" onClick={trackClickLogo} className={twMerge('h-full', className)}>
            <Image
                alt="SuaQuadra - Aluguel de imóveis comerciais em São Paulo"
                src={
                    {
                        dark: logoDark,
                        color: logoColor,
                    }[variant]
                }
                width={120}
                height={23}
                quality={100}
                loading={'eager'}
                priority={true}
                className="size-full"
            />
        </Link>
    )
})

Logo.displayName = 'Logo'

export default Logo
