import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

export type BadgeVariant = 'green' | 'red' | 'yellow' | 'gray'

type Props = ComponentProps<'span'> & {
  variant: BadgeVariant
}

const VARIANT_TO_CLASS = {
  green: 'bg-sq-green-100 text-sq-green-900',
  red: 'bg-sq-red-100 text-sq-red-900',
  yellow: 'bg-sq-yellow-100 text-sq-yellow-900',
  gray: 'bg-sq-gray-100 text-sq-gray-700',
} satisfies Record<BadgeVariant, string>

export default function Badge({ variant, className, children }: Props) {
  return (
    <span
      className={twMerge(
        'mb-1 mr-auto rounded-lg px-2 py-1 text-sm',
        VARIANT_TO_CLASS[variant],
        className
      )}
    >
      {children}
    </span>
  )
}
