import React from 'react'

type Props = {
  imageUrl: string
  addressFirstLine: string
  addressSecondLine: string
  addressThirdLine: string
}

export default function SupplyPreview({
  imageUrl,
  addressFirstLine,
  addressSecondLine,
  addressThirdLine,
}: Props) {
  return (
    <div className="flex flex-row items-center gap-4">
      <img
        src={imageUrl}
        alt={addressFirstLine}
        className="pointer-events-none size-20 select-none rounded-lg"
      />
      <div className="text-ellipsis text-sm">
        <p className="line-clamp-1 font-bold">{addressFirstLine}</p>
        <p className="line-clamp-1">{addressSecondLine}</p>
        <p className="line-clamp-1">{addressThirdLine}</p>
      </div>
    </div>
  )
}
