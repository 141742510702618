// EXAMPLE:
//https://res.cloudinary.com/suaquadra/image/upload/c_fill,h_768,q_100,w_1024/upload-images/wdslukcklislerrytuqx.png

export const SKIP_LOADER_FLAG = 'skip_transformation'
export default function CloudinaryImageLoader({ src, width, quality }) {
    if (src.includes('res.cloudinary')) {
        if (src.includes(SKIP_LOADER_FLAG)) {
            return src + `?w=${width}&q=${quality || 'auto'}`
        }

        const parsedSrc = src.split('upload/')
        const params = ['f_auto', 'c_fill', `w_${width}`, `q_${quality || 'auto'}`]
        return `${parsedSrc[0]}upload/${params.join(',')}/${parsedSrc[1]}`
    }
    return `${src}?w=${width}&q=${quality || 'auto'}`
}
