import React, { PropsWithChildren } from 'react'

export default function ListMessage({ children }: PropsWithChildren<{}>) {
  return (
    <div className="flex w-full flex-col justify-center gap-4 divide-y divide-sq-gray-200 first:*:pt-0">
      {children}
    </div>
  )
}

type ItemProps = {
  title: string
  className?: string
}

function ListMessageItem({ title, children, className }: PropsWithChildren<ItemProps>) {
  return (
    <div className="flex flex-col pt-4">
      <h3 className="font-bold leading-normal">{title}:</h3>
      <div className={className}>{children}</div>
    </div>
  )
}

ListMessage.Item = ListMessageItem
